<template>
	<form @submit.prevent="$emit('save')" :disabled="disabled">
		<vue-form-generator :schema="schema" :model="model" :options="formOptions"></vue-form-generator>
	</form>
</template>

<script>
import projects from "../../api/modules/projects";
import games from "../../api/modules/games";

export default {
	components: {},
	props: {
		value: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {

		return {
			formOptions: {
				validateAfterLoad: true,
				validateAfterChanged: true,
				validateAsync: true
			},
			gamePaths: [],
			projects: []
		}
	},
	async mounted() {
		this.projects = await projects.getAllProjectConfig()
		this.gamePaths = await games.getGamePaths()
	},
	computed: {
		disabled() {
			return !this.canWrite('games')
		},
		schema() {

			let fields = [
				{
					type: 'input',
					inputType: 'text',
					label: 'Name',
					model: 'Name',
					featured: true,
					required: true,
					disabled: this.disabled
				},
				{
					type: 'switch',
					label: 'Label Enabled',
					model: 'Label.Enabled',
					required: true,
					disabled: this.disabled
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Label',
					hint: 'Text shown over game icon',
					model: 'Label.Text',
					required: true,
					disabled: this.disabled,
					maxlength: 12
				},
				{
					type: "color",
					label: "Label background color",
					model: "Label.BackgroundColor"
				},
				{
					type: "color",
					label: "Label text color",
					model: "Label.TextColor",
					options: {
						swatches: 'text-advanced'
					}
				},
				{
					type: 'input',
					inputType: 'text',
					label: 'Demo URL',
					model: 'DemoUrl',
					required: true,
					disabled: this.disabled
				},
				{
					type: "image",
					label: "Squared icon",
					hint: '512x512',
					model: "Assets.icon",
					required: true,
					disabled: this.disabled
				},
				{
					type: "checklist",
					label: "Certifications",
					model: "Certifications",
					listBox: true,
					values: [
						{value: "it", name: "Italy"},
						{value: "it-p", name: "Italy (certification in progress)"},
					]
				},
				{
					type: 'switch',
					label: 'Open in new window?',
					model: 'OpenInNewWindow',
					disabled: this.disabled
				},
				{
					type: 'switch',
					label: 'Enabled',
					model: 'Enabled',
					disabled: this.disabled
				},
				{
					type: 'select',
					label: 'Client folder',
					model: 'ClientFolder',
					required: true,
					values: () => this.gamePaths.map(p => ({
						id: p,
						name: p
					})),
					disabled: this.disabled
				},
			]

			let projectConfigs = []

			this.projects.forEach(p => {
				p.ProjectConfigs.forEach(pc => {
					projectConfigs.push({
								name: `${p.Name} - ${pc.Name} (${pc.Status.toUpperCase()})`, id: pc.Id, group: p.Name
							}
					)
				})
			})

			fields.push({
				type: "select",
				label: "Engine Configuration",
				model: "ProjectConfigfk",
				required: true,
				values: projectConfigs,
			})

			return {
				fields
			}
		},
		model: {
			get() {
				return this.value
			},
			set(_val) {
				this.$emit('input', _val)
			}
		}
	}
}
</script>
