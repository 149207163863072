<template>
	<div>
		<game-form v-model="model"></game-form>
		<button class="btn btn-primary" @click="save">SAVE</button>
	</div>
</template>

<script>
import GameForm from "../../components/games/game-form";
import api from '@/api'

export default {
	name: "new",
	components: {GameForm},
	data: () => ({
		model: {
			Name: '',
			DemoUrl: '',
			Enabled: true,
			Assets: {},
			Label: {
				Enabled: false,
				Text: "",
				BackgroundColor: "",
				TextColor: ""
			}
		}
	}),
	methods: {
		async save() {
			let game = await api.Games.saveNew(this.model)
			console.log("Saved", game)

			this.$router.replace({
				name: 'games-game_id',
				params: {
					game_id: game.Id
				}
			})

		}
	}
}
</script>

<style scoped>

</style>
